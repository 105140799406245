
<!-- 推荐 -->

<template>
    <div >
      <div class="m-t-10">
		<div  v-if="!showmoban&&leftlist.length">
			<h1 class="f-28 bold h-60 l-h-60 m-b-10 tc" ><i class="iconfont b2b-a-zu1083 classicon absolute l-0 r-0 m-a f-18"></i>  为您推荐</h1>
			<div class="flex warp start"  >
				<div @click="godetail(item.id)" v-for="(item,k) in leftlist" class="w-230 h-325 bg-f rad-2 p-20 m-b-10 m-r-12" >
					<img class="w-192  h-192 m-b-20" v-if="item.imageurl" :src="item.imageurl" alt="">
					<img class="w-192  h-192 m-b-20" v-else src="@/assets/img/mr.png" alt="">
					<p class="c-3 f-14 ell m-b-10"> {{item.goodsname}}</p>
					<p class="c-6 m-b-15">{{item.spec}}</p>
					<price class="c-ff3" :price="item.price" :size="20"/>
				</div>
			</div>
		</div>
		
		<div  class="flex warp"  v-if="showmoban&&!leftlist.length">
			<div v-for="item in 10" class="w-230 h-325 bg-f rad-2 p-20 m-b-10">
				<img class="w-192  h-192 m-b-20" src="@/assets/img/mr.png" alt="">
				<p class="c-3 f-14 ell m-b-10">通用名称</p>
				<p class="c-6 m-b-15">商品产品规格</p>
				<p class="c-ff3 bold"><span class="f-12">￥</span>75<span class="f-12">.50</span> </p>
			</div>
		</div>
      		
      </div>
    </div>
</template>

<script>
export default {
    name: 'recommend',
	data() {
		return{
			leftlist:[],
			showmoban:false
		}
	},
    props: {
        formData: {
            type: Object,
            default: () => ({})
        }
    },
	watch:{
		formData:function(e){
			this.formData=e
			console.log('formData',e)
			if(this.$route.path=='/edit'){
				this.showmoban=true
			}
			this.articledetail()
		}
	},
	created() {
		this.articledetail()
	},
	methods: {
		
		// 获取专场列表 
		getByPid(){
			
			this.requset('/b2bgoods/selectSpecialByPid',{typeid:6}).then((resp)=> {
				resp.data.map(res=>{
					if(res.id==formData.SelectWidget)smallitem[0]=res
					if(res.id==formData.SelectWidget1)smallitem[1]=res
				})
				this.smallitem=smallitem
			});
		},
		
		// 文章更多
		Morenews: function Morenews(item) {
			this.$router.push({path:'/newslist',query:{type:item,showdetail:false}})
		},
		
		
		// 获取推荐商品
		articledetail: function articledetail(id, type) {
			this.requset('/b2bgoods/querycategorygoods',{specialid:this.formData.SelectWidget,pagesize:20}).then((res)=> {
				console.log('小转场',res)
				this.leftlist=res.data||[]
			});
		},
		
		// // 页面跳转到详情
		godetail: function godetail(id) {
			if(location.href.indexOf('edit')==-1){
				document.documentElement.scrollTop=0
				this.$router.push({path:'/detail',query:{goodsid:id}})
			}
		},
		
		
	},
};
</script>

<style>
   .box{}
   .m-r-12:nth-child(5n){margin-right: 0;}
</style>
